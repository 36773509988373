import React, { useState, useEffect } from 'react'
import "twin.macro"
import { ErrorPage } from '../components/errorPage'

const Error = () => <ErrorPage 
	title="Error" 
	details={<p>There was an error retrieving your file.</p>}
	displayAds={false}
/>

export default Error